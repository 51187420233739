import React from 'react'
import useLocale from '@logdoc/l10n';
import LayoutDefault from "../layouts";
import * as classes from '../sass/style.module.scss'
import { L10nProvider } from '@logdoc/l10n';

const Co = () => {
	return (
		<L10nProvider>
			<Contacts />
		</L10nProvider>
	)
}

export const Contacts = () => {
	const t = useLocale()
	return (
		<LayoutDefault>
			<section className={classes.sec}>
				<h2 className={classes.secTitle}>
					{t('contacts.title')}
				</h2>
				<div className={classes.secBlock}>
					<div className={classes.contact}>
						<div className={classes.contactItem}>
							<div className={classes.contactEmail}>
								<a href="https://t.me/logdoc">Telegram Community</a>
							</div>
							<div className={classes.contactInfo}>
								<div className={classes.contactName}>
									{t('contacts.tg.p1')}
								</div>
								<div className={classes.contactPosition}>
									Ответы на вопросы, анонсы, новости
								</div>
							</div>
						</div>
						<div className={classes.contactItem}>
							<div className={classes.contactEmail}>
								<a href="mailto:support@logdoc.org">
									support@logdoc.org
								</a>
							</div>
							<div className={classes.contactInfo}>
								<div className={classes.contactName}>
									{t('contacts.support.p1')}
								</div>
								<div className={classes.contactPosition}>
									{t('contacts.support.p2')}
								</div>
							</div>
						</div>
						<div className={classes.contactItem}>
							<div className={classes.contactEmail}>
								<a href="mailto:biz@logdoc.org">
									biz@logdoc.org
								</a>
							</div>
							<div className={classes.contactInfo}>
								<div className={classes.contactName}>
									{t('contacts.biz.p1')}
								</div>
								<div className={classes.contactPosition}>
									{t('contacts.biz.p2')}
								</div>
							</div>
						</div>
						<div className={classes.contactItem}>
							<div className={classes.contactEmail}>
								<a href="https://github.com/LogDoc-org" target="_blank">
									GitHub LogDoc
								</a>
							</div>
							<div className={classes.contactInfo}>
								<div className={classes.contactName}>
									{t('contacts.github.p1')}
								</div>
								<div className={classes.contactPosition}>
									{t('contacts.github.p2')}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</LayoutDefault>
	);
};

export default Co;
