import React from 'react'
import { L10nProvider } from '@logdoc/l10n';
import { Contacts } from '../contacts'

const Co = () => {
	return (
		<L10nProvider>
			<Contacts />
		</L10nProvider>
	)
}

export default Co;
